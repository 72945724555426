import React from "react"
import { Link } from "gatsby"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import SideBarMenu from "@components/sidebar/sidebar"
import Sticky from "react-sticky-el"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ location }) => (
  <header className={location === "no-heading" ? `dark-header-text` : ""}>
    <Sticky
      className="sticky-wrapper"
      stickyClassName="is-sticky"
      stickyStyle={{ transform: "unset", zIndex: "10" }}
    >
      <div className="header">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/" className="d-flex align-items-center logo-link">
              <div className="header-logo header-logo-dark">
                <StaticImage
                  placeholder="none"
                  src="../../images/webriq-glue-logo.png"
                  alt="WebriQ"
                  width={70}
                />
              </div>
              <div className="header-logo header-logo-light">
                <StaticImage
                  placeholder="none"
                  src="../../images/webriq-glue-logo-dark.png"
                  alt="WebriQ"
                  imgClassName="header-logo-light"
                  width={70}
                />
                {/*<span className="header-logo-text">WebriQ</span>*/}
              </div>
            </Link>
            <div className="d-flex justify-content-between align-items-center">
              <ul className="list-inline d-none d-md-inline header-links">
                <li className="list-inline-item">
                  <a
                    className="login"
                    href="https://app.webriq.com/auth/login"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Login
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="register"
                    href="https://app.webriq.com/auth/register"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Register
                  </a>
                </li>
              </ul>
              <SideBarMenu />
              <div className="toggler-wrapper">
                <ThemeToggler>
                  {({ theme, toggleTheme }) => (
                    <label className="dayNight">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          toggleTheme(e.target.checked ? "dark" : "light")
                        }
                        checked={theme === "dark"}
                        title="Toggle Light/Dark Mode"
                      />
                      <div></div>
                    </label>
                  )}
                </ThemeToggler>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
