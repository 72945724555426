import React from "react"
import { Link } from "gatsby"
import WebriqForm from "@webriq/gatsby-webriq-form"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { StaticImage } from "gatsby-plugin-image"

const footer = ({ location }) => {
  return (
    <footer className={location === "auxiliary" ? `alt-footer-style` : ""}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1877 442">
        <g>
          <path
            className="divider-part-shadow"
            d="M1931.79,14.42c.45-5.76-3.25-8.06-8.21-5.12L1489,266.94c-5,2.94-13.41,3.61-18.78,1.49L1191.8,158.27a41.89,41.89,0,0,0-20-1.8L-127.85,417c-5.66,1.14-10.66,6.61-11.12,12.16a10.36,10.36,0,0,0,9.64,10.95L1875.31,603.64a10.47,10.47,0,0,0,11.27-9.61Z"
          />
        </g>
        <path
          className="divider-part-orange"
          d="M2180.82-161.52c-7.15-.18-18,2.63-24.2,6.26L1568.23,190.89c-6.16,3.63-14,11.7-17.54,18L1409.12,462.57c-3.48,6.25-1,9,5.54,6.07l1370.16-609c6.54-2.91,6-5.44-1.11-5.62Z"
        />
        <path
          className="divider-part-teal"
          d="M-176.14,407c-11.79-2.7-11.74-6.88.12-9.29L944.51,170.05c11.85-2.41,30.82-.9,42.14,3.35l875.56,328.72c11.33,4.25,10.7,7.45-1.39,7.11L72.51,459.53A268.87,268.87,0,0,1,29.09,454Z"
        />
        <path
          className="footer-divider-part-main"
          d="M1928.87,50.22c.45-5.75-3.25-8-8.21-5.11L1486.09,302.74c-5,2.94-13.41,3.62-18.78,1.49L1188.88,194.07a41.88,41.88,0,0,0-20.05-1.79L-130.78,452.79c-5.65,1.13-10.66,6.6-11.11,12.15a10.34,10.34,0,0,0,9.64,10.95L1872.39,639.45a10.49,10.49,0,0,0,11.27-9.61Z"
        />
      </svg>
      <div className="footer-wrapper pb-5">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-3 col-lg-2 mt-5 mt-md-0">
              <span className="d-block h6 mb-3 font-weight-bold">Company</span>
              <ul className="list-unstyled">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <a
                    href="https://www.webriq.com/about-us/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.webriq.com/contact-us/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.webriq.com/terms/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.webriq.com/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.webriq.com/gdpr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    About GDPR
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.webriq.com/cookies/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cookies
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-lg-2 mt-5 mt-md-0 pr-lg-3">
              <span className="d-block h6 mb-3 font-weight-bold">
                We&#39;re Social!
              </span>
              <ul className="list-unstyled">
                <li>
                  <a
                    href="https://www.facebook.com/webriqgoesmad"
                    target="_blank"
                    rel="noreferrer"
                  >
                    WebriQ on Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/WebriQgoesMad"
                    target="_blank"
                    rel="noreferrer"
                  >
                    WebriQ on Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/philippebodart/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Philippe on LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/alexbelding/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Alex on LinkedIn
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-lg-2 mt-5 mt-md-0">
              <span className="d-block h6 mb-3 font-weight-bold">Services</span>
              <ul className="list-unstyled">
                <li>
                  <a
                    href="https://www.webriq.com/gomadservices/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mad Services
                  </a>
                </li>
                <li>
                  <a
                    href="https://glue.webriq.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Glue Services
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.webriq.com/gomadpricing/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mad Pricing
                  </a>
                </li>
                <li>
                  <Link to="/pricing/">Glue Pricing</Link>
                </li>
                <li>
                  <a
                    href="https://www.webriq.com/ecommerce/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    eCommerce
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.webriq.com/headless-ecommerce/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Headless eCommerce
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.webriq.com/seo/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SEO
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-lg-3 mt-5 mt-md-0">
              <span className="d-block h6 mb-3 font-weight-bold">
                Resources
              </span>
              <ul className="list-unstyled">
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://res.cloudinary.com/dnla85pdq/image/upload/v1604823096/webriq/CURRENT_WebriQ_Goes_Mad_Presentation.pdf"
                  >
                    Websites as a Service Explained
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://res.cloudinary.com/dnla85pdq/image/upload/v1594358323/webriq/files/The_3_Pillars_of_Modern_Digital_Marketing.pdf"
                  >
                    Modern Web Marketing
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://res.cloudinary.com/dnla85pdq/image/upload/v1594358349/webriq/files/headless-ecommerce.pdf"
                  >
                    Headless eCommerce
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://res.cloudinary.com/dnla85pdq/image/upload/v1596552225/webriq/files/Why_Outsource_Your_Web_Development.pdf"
                  >
                    Outsourcing Web development
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-10 col-lg-2 mt-5 mt-md-0">
              <span className="d-block h6 mb-3 font-weight-bold">
                Our Partners
              </span>
              <div className="d-lg-block d-md-flex">
                <Link
                  className="d-inline-block my-3 mr-3 pr-2"
                  to="/engagement"
                >
                  <StaticImage
                    placeholder="none"
                    src="../../images/footer/partners_zoho.png"
                    width={125}
                    alt="Zoho Authorized Partner"
                  />
                </Link>
                <a
                  className="d-inline-block my-3 mr-3 pr-2"
                  href="https://www.socialseo.com/digital-marketing-services/local-seo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    placeholder="none"
                    src="../../images/footer/partners_socialseo.png"
                    width={125}
                    alt="Social SEO"
                  />
                </a>
                <a
                  className="d-inline-block my-3 mr-3 pr-2"
                  href="https://www.webriq.com/headless-ecommerce"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    placeholder="none"
                    src="../../images/footer/ecwid.png"
                    width={125}
                    alt="Ecwid"
                  />
                </a>
                <Link
                  className="d-inline-block my-3 mr-3 pr-2"
                  to="/headless-ecommerce"
                >
                  <StaticImage
                    placeholder="none"
                    src="../../images/footer/partners_bigcommerce.svg"
                    width={125}
                    alt="BigCommerce"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-5 text-center mt-md-0 text-md-left">
            <span className="h6 font-weight-bold d-inline-block">
              Join our mailing list to receive updates.
            </span>
            <div className="d-inline-block">
              <LazyLoadComponent>
                <WebriqForm
                  id="newsletter"
                  webriqform="true"
                  name="Newsletter"
                  className="newsletter-form"
                  data-form-id="038271e6-6317-412c-9704-c7bffb3bb485"
                  data-thankyou-url="https://www.webriqgoesmad.webriq.com/pricing/"
                >
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="info@mail.com"
                      required
                    />
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                    <div className="webriq-recaptcha" />
                  </div>
                </WebriqForm>
              </LazyLoadComponent>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrapper pb-5 small">
        <div className="container">
          <span>
            &copy; {new Date().getFullYear()} WebriQ Pte Ltd. All rights
            reserved.
          </span>
        </div>
      </div>
    </footer>
  )
}

export default footer
