import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import Facebook from "@svg/facebook"
import Twitter from "@svg/twitter"
import LinkedIn from "@svg/linkedin"

const Menu = () => (
  <>
    <Nav className="flex-column">
      <Nav.Item>
        <a href="https://www.webriq.com/" target="_blank" rel="noreferrer">
          Home
        </a>
      </Nav.Item>
      <Nav.Item>
        <Link to="/pricing/">Pricing</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/is-webriq-glue-for-you/">Is WebriQ GLUE for YOU?</Link>
      </Nav.Item>
      <Nav.Item>
        <a href="https://webriq.com/blog/" target="_blank" rel="noreferrer">
          Blog
        </a>
      </Nav.Item>
      <Nav.Item>
        <a
          href="https://www.webriq.com/showcase/"
          target="_blank"
          rel="noreferrer"
        >
          Showcase
        </a>
      </Nav.Item>
      <Nav.Item>
        <a href="https://webriq.com/agency/" target="_blank" rel="noreferrer">
          Partners
        </a>
      </Nav.Item>
    </Nav>
    <div className="mt-5">
      <ul className="list-inline socials">
        <li className="list-inline-item">
          <a
            href="https://www.facebook.com/webriqgoesmad"
            target="_blank"
            rel="noreferrer"
            className="social-round"
            aria-label="Facebook"
          >
            <Facebook />
          </a>
        </li>
        <li className="list-inline-item">
          <a
            href="https://twitter.com/WebriQgoesMad"
            target="_blank"
            rel="noreferrer"
            className="social-round"
            aria-label="Twitter"
          >
            <Twitter />
          </a>
        </li>
        <li className="list-inline-item">
          <a
            href="https://www.linkedin.com/in/philippebodart/"
            target="_blank"
            rel="noreferrer"
            className="social-round"
            aria-label="Philippe Bodart LinkedIn"
          >
            <LinkedIn />
          </a>
        </li>
        <li className="list-inline-item">
          <a
            href="https://www.linkedin.com/in/alexbelding/"
            target="_blank"
            rel="noreferrer"
            className="social-round"
            aria-label="Alex Belding Linkedin"
          >
            <LinkedIn />
          </a>
        </li>
      </ul>
    </div>
  </>
)

export default Menu
