import React from "react"

const TwitterIcon = () => (
	<>
		<svg
			className="social-icon twitter"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 52.851 52.851"
			style={{ enableBackground: "new 0 0 52.851 52.851" }}
			xmlSpace="preserve"
		>
			<g>
				<path
					d="M52.412,9.656c-0.354-0.24-0.823-0.227-1.165,0.031c-0.449,0.34-1.179,0.61-1.965,0.818
		c2.047-2.294,2.091-3.826,2.069-4.178c-0.023-0.364-0.242-0.687-0.572-0.842c-0.331-0.156-0.719-0.118-1.014,0.096
		c-2.303,1.674-4.597,2.027-5.961,2.063c-2.094-2.004-4.813-3.102-7.707-3.102c-6.196,0-11.236,5.108-11.236,11.386
		c0,0.54,0.038,1.079,0.113,1.613C14.236,17.42,5.516,7.131,5.426,7.024c-0.21-0.25-0.528-0.384-0.854-0.355
		c-0.326,0.028-0.617,0.213-0.78,0.497C0.702,12.526,2.77,17.234,4.66,19.93c-0.334-0.178-0.605-0.354-0.767-0.473
		c-0.3-0.224-0.701-0.261-1.039-0.095c-0.337,0.166-0.552,0.506-0.559,0.881c-0.083,5.108,2.375,8.034,4.687,9.652
		c-0.308-0.029-0.621,0.085-0.835,0.318c-0.24,0.261-0.325,0.63-0.221,0.969c1.597,5.227,5.518,7.003,8.013,7.606
		c-5.13,3.994-12.68,2.708-12.763,2.694c-0.456-0.081-0.906,0.159-1.092,0.582c-0.185,0.422-0.059,0.917,0.309,1.197
		c5.415,4.133,11.892,5.048,16.57,5.048c3.539,0,6.05-0.524,6.29-0.577c23.698-5.616,24.365-27.323,24.31-30.88
		c4.449-4.137,5.144-5.713,5.251-6.103C52.929,10.336,52.767,9.896,52.412,9.656z M45.874,15.691c-0.223,0.205-0.34,0.5-0.32,0.803
		c0.063,0.96,1.275,23.597-22.742,29.288c-0.109,0.023-9.656,2.015-17.932-2.085c3.497-0.097,8.511-1.013,12.004-4.935
		c0.264-0.295,0.328-0.719,0.164-1.079c-0.162-0.357-0.519-0.586-0.91-0.586c-0.003,0-0.007,0-0.01,0
		c-0.05,0.032-5.301-0.006-7.705-5.001c0.968,0.055,2.162-0.005,3.113-0.443c0.392-0.181,0.623-0.592,0.575-1.021
		c-0.048-0.428-0.366-0.777-0.788-0.866c-0.269-0.057-6.115-1.364-6.933-7.741c0.887,0.388,2.022,0.705,3.144,0.534
		c0.386-0.058,0.702-0.335,0.811-0.71s-0.01-0.779-0.305-1.035c-0.25-0.218-5.74-5.097-3.137-11.39
		c2.826,2.965,11.196,10.67,21.337,10.088c0.297-0.017,0.572-0.167,0.749-0.407c0.176-0.24,0.236-0.546,0.164-0.835
		c-0.192-0.765-0.29-1.553-0.29-2.341c0-5.176,4.144-9.386,9.237-9.386c2.491,0,4.828,0.994,6.579,2.8
		c0.184,0.19,0.437,0.299,0.701,0.304c1.06,0.015,2.802-0.11,4.77-0.899c-0.568,0.707-1.402,1.554-2.629,2.518
		c-0.347,0.273-0.474,0.74-0.313,1.151c0.161,0.412,0.577,0.671,1.011,0.632c0.233-0.019,1.421-0.123,2.764-0.414
		C48.249,13.423,47.246,14.429,45.874,15.691z"
				/>
			</g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
		</svg>
	</>
)

export default TwitterIcon
