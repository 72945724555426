import React from "react"
import SideBar from "react-sidebar"
import Menu from "./menu"
import MenuButton from "@images/menu.svg"

class SideBarMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      sidebarpullRight: true,
      toggle: false,
    }

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
  }
  onSetSidebarOpen(state) {
    this.setState({ sidebarOpen: state.open, toggle: !this.state.toggle })
  }
  toggleMenu() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
      toggle: !this.state.toggle,
    })
  }

  handleKeyDown = (ev) => {
    // check keys if you want
    if (ev.keyCode === 13) {
      this.focus()
    }
  }

  focus = () => this.ref.focus

  handleRef = (component) => {
    this.ref = component
  }

  render() {
    // const style = {
    //  overflow: "visible",
    //  cursor: "pointer",
    //  // disable touch highlighting on devices
    //  WebkitTapHighlightColor: "rgba(0,0,0,0)",
    // }
    return (
      <SideBar
        sidebar={
          <Menu /> // Menu list
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        pullRight={this.state.sidebarpullRight}
        sidebarClassName={"sidebar-menu"}
        styles={{
          sidebar: {
            width: "300px",
            position: "fixed",
            zIndex: "3",
          },
          root: { position: "relative", overflow: "visible" },
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.65)", zIndex: 2 },
          content: {
            position: "relative",
            overflow: "visible",
            cursor: "pointer",
          },
        }}
      >
        <button
          className="btn btn-primary d-inline-block menu-btn"
          onClick={(e) => this.toggleMenu(e)}
          onKeyDown={(e) => this.handleKeyDown(e)}
        >
          <img width={16} src={MenuButton} alt="Menu" />
        </button>
      </SideBar>
    )
  }
}

export default SideBarMenu
